import {DashboardConfig} from "./DashboardConfig";
import {buildLineItemsStorePersistence} from "../../lineitems-store/RestLineItemsStore.persistence";
import {
  LineItemsStore,
  mergeTemplateStoreIntoStore, PersistenceQuery, pQuery, StoreQuery
} from "../../ps-models/lineitems-store";
import {useUpdateContext} from "../../UpdateContext";
import {BuilderContext, getWidgetTypes} from "./WidgetRegistry";
import {AmDashboard, Company, DateRangeType, FiltersDetails} from "../../ps-types";
import {getAmDashboard, updateAmDashboard} from "./Dashboard.client";
import {authStorage} from "../../auth";
import {
  buildParameterLineItem,
  LineItem,
  LineItemsFieldSet, ParameterLineItem,
  TimedLineItem,
  ValueType
} from "../../ps-models/line-items";
import {
  addTime,
  FinancialValueType,
  getAmProjectConfig,
  GROUPING_LINE_ITEM_KEY,
  MONTHLY_TIME_INDEX,
  storeValueTypeField, TimeUnits
} from "../../ps-models";
import {
  isTemplateLineItem,
} from "../../ps-models/line-items";
import {EventHandler, EVENTS} from "./EventHandler";
import {values} from "ramda";
import {CompanyModule, loadCompanyModule} from "./DashboardConfigServiceRegistry";

export function useDashboardService() {
  return useUpdateContext<DashboardConfigService>();
}


export async function buildConfigServiceFromDashboard(companyId: string, dashboardId: string, readOnly: boolean, constraints?: {viewDateRange?: DateRangeType, storeIds?: string[]}) {
  let dashboard = await getAmDashboard(companyId, dashboardId)
  return  await buildConfigService(dashboard, readOnly, constraints);
}

export  async function buildConfigService(dashboard: AmDashboard, readOnly: boolean = false, constraints?: {viewDateRange?: DateRangeType, storeIds?: string[]}) {
  let service = new DashboardConfigService(dashboard, readOnly, constraints);

  if(!service.getConfig().isProjectVersionSelectorExposed()) {
    await service.loadStore({refetch: true});
  }


  return service;
}

const DEFAULT_INHERITED_FIELDS_ON_GROUP_AGGREGATE = ['section', 'subsection', 'group'];

export class DashboardConfigService {

  static TIME_SLOTS_PAGE_SIZE = 12*5;

  private selectedWidget: string = '';
  private dashboardConfig: DashboardConfig = new DashboardConfig();
  private store: LineItemsStore;

  private templateStore: LineItemsStore = new LineItemsStore(MONTHLY_TIME_INDEX, {storeType: "template"});

  private storeToCompareWith?: LineItemsStore;

  private reportTemplateExcelFile?: File;

  //Changes to globalContext should trigger a re-render
  private globalContext: any = {};

  private isLoading = true;

  //Changes to globalProps should not trigger a re-render
  private globalProps: any = {};

  handler: EventHandler = new EventHandler();

  private storeIdsForScenarioComparison: string[]  = [];

  private companyModule: CompanyModule //To override behaviors for specific companies

  constructor(public amDashboardDto: AmDashboard,
              public readOnly: boolean = false,
              public constraints?: {viewDateRange?: DateRangeType, storeIds?: string[]},
  ) {
    this.dashboardConfig = DashboardConfig.deserialize(
      amDashboardDto.data,
      this.handler
    );

    this.handler.addListener(EVENTS.QUERY_CHANGED,  () => {
      this.loadStore({type:"main",refetch: true});
    });

    this.store = new LineItemsStore(MONTHLY_TIME_INDEX);

    this.companyModule = loadCompanyModule();

  }

  storeIsLoading(){
    return this.isLoading;
  }

  onConfigChanged(widgetId: string, callback: (event: any)=>void) {
    this.handler.addListener(EVENTS.WIDGET_CONFIG_CHANGED, (event) => {
      if(event.data.widgetId === widgetId) {
        callback(event);
      }
    })
  }


  setStoreIdsForScenarioComparison(storeIds: string[]){
    console.info("PROC: setStoreIdsForScenarioComparison", storeIds)

    if(storeIds.length === 0){
      this.storeToCompareWith = undefined;
    } else {
      this.storeIdsForScenarioComparison = storeIds;
      this.loadStore({type:"comparison", refetch: true});
    }
  }

  setGlobalProp(key: string, value: any){
    this.globalProps[key] = value;
  }

  getGlobalProp(key: string){
    return this.globalProps[key];
  }


  getGlobalContext(){
    return this.globalContext;
  }

  getFilterDetailsFromGlobalContext(): FiltersDetails | undefined {
    return this.globalContext?.filterDetails
  }
  getParamsMap(){
    return this.getConfig().getExtraLineItems({ getByTypes: ["Parameter"] })
        .map((li) => {
          return { [li.name]: (li as ParameterLineItem).getValue().value };
        })
        .reduce((a, b) => ({ ...a, ...b }), {});
  }
  setGlobalContextValue(key: string){
    return this.globalContext[key];
  }

  onQueryChanged(callback: (event: any)=>void) {
    this.handler.addListener(EVENTS.QUERY_CHANGED, callback)
  }

  onStoreLoaded(callback: (event: LineItemsStore)=>void) {
    this.handler.addListener(EVENTS.STORE_LOADED, callback)
  }

  onStoreReady(callback: (event: any)=>void) {
    this.handler.addListener(EVENTS.STORE_READY, callback);
  }

  onStoreLoading(callback: (event: any)=>void) {
    this.handler.addListener(EVENTS.STORE_LOADING, callback);
  }

  onCompareWithStoreIdsChanged(callback: (event: any)=>void) {
    this.handler.addListener(EVENTS.COMPARED_WITH_STORE_IDS_CHANGED, callback)
  }

  onWidgetSelected(callback: (event: any)=>void) {
    this.handler.addListener(
      EVENTS.WIDGET_CONFIG_SELECTED, callback
    )
  }

  onGlobalContextChanged(callback: (event: any)=>void) {
    this.handler.addListener(
      EVENTS.GLOBAL_CONTEXT_CHANGED, callback
    )
  }

  getConfig() {
    return this.dashboardConfig;
  }

  getWidgetConfig(id: string) {
    return this.dashboardConfig.getByID(id)?.config;
  }

  getEntireWidgetConfig(id: string){
    return this.dashboardConfig.getByID(id);
  }


  addExtraLineItem(lineItem: LineItem) {
    const isATemplateLineItem = isTemplateLineItem(lineItem)
    this.dashboardConfig.addExtraLineItem(lineItem);
    if(isATemplateLineItem){
      this.templateStore?.getDataSet().addLineItem(lineItem);
    } else {
      this.store.getDataSet().addLineItem(lineItem);
    }
  }

  setParameter(name: string, value: ValueType, valueType: FinancialValueType = "number") {
    // @TODO: If the item already exists on the store. Decide what needs to be done.
    // @TODO: #2 The store should be reloaded in a simpler manner
     this.addExtraLineItem(buildParameterLineItem(
       name,
       value,
       LineItemsFieldSet.fromMap(storeValueTypeField(valueType)))
     )
    this.clearCache();
     this.markStoreAsReady()
  }


  updateGlobalContext(context: any) {
    this.globalContext = {...this.globalContext, ...context};
    this.processPreRenders();
    this.handler.fireEventDebounced(EVENTS.GLOBAL_CONTEXT_CHANGED)
  }

  setLineItemExtensions(extensions: any[]) {
    this.dashboardConfig.setLineItemExtensions(extensions);
    this.markStoreAsReady()
  }

  setNavigationConfig(config: AmDashboard['navigationConfig']) {
    this.amDashboardDto.navigationConfig = config;
  }

  setInjectionTemplateConfig(config: AmDashboard['injectionTemplateConfig']) {
    this.amDashboardDto.injectionTemplateConfig = config;
  }

  setReportTemplateExcelFile(file: File){
    this.reportTemplateExcelFile = file;
  }

  setExposeAsInjectionTemplate(set: boolean) {
    this.amDashboardDto.exposedAsInjectionTemplate = set;
  }

  getExposedAsInjectionTemplate(){
    return this.amDashboardDto.exposedAsInjectionTemplate;
  }

  getNavigationConfig() {
    return this.amDashboardDto.navigationConfig;
  }

  getInjectionTemplateConfig() {
    return this.amDashboardDto.injectionTemplateConfig;
  }

  getOutputs(widgetMachineName: string) {
    return this.dashboardConfig.getByMachineName(widgetMachineName)?.getOutputs() || {};
  }

  setOutput(widgetId: string, key: string, value: any) {
    this.dashboardConfig.getByID(widgetId).setOutput(key, value);
  }

  getAllWidgets(){
    return values(this.dashboardConfig.getWidgets())
  }

  setConfig(dashboardConfig: DashboardConfig) {
    this.dashboardConfig = dashboardConfig;
  }

  selectWidget(id: string) {
    this.selectedWidget = id;
    this.handler.fireEvent(EVENTS.WIDGET_CONFIG_SELECTED, {widgetId: id})
  }

  getSelectedWidget() {
    return this.dashboardConfig.getByID(this.selectedWidget)
  }

  hasSelectedWidget() {
    return this.selectedWidget !== '';
  }

  unselectWidget() {
    this.selectedWidget = '';
    this.handler.fireEvent(EVENTS.WIDGET_CONFIG_SELECTED, {})
  }


  getDefaultTimeSlotSize(){
    return DashboardConfigService.TIME_SLOTS_PAGE_SIZE
  }

  updateDateRange(dateRange: DateRangeType, granularity= this.getDefaultGranularity()){
    this.updateGlobalContext({
      granularity,
      utcDateRange: dateRange});
  }

  private getDateRangeUsingDefaultTimeSlotSize(startDate: Date, granularity = this.getDefaultGranularity()){
    let from = startDate;
    let to = this.getEndDateUsingDefaultTimeSlotSize(startDate, granularity);
    if(this.constraints?.viewDateRange){
      from = this.constraints?.viewDateRange.from < startDate ? startDate: this.constraints?.viewDateRange.from;
      to = this.constraints?.viewDateRange.to > to ? to: this.constraints?.viewDateRange.to;
    }
    return {from, to}
  }

  getEndDateUsingDefaultTimeSlotSize(startDate: Date, granularity = this.getDefaultGranularity()){
    let endDate = addTime(startDate, this.getDefaultTimeSlotSize(), granularity);
    if(endDate > this.store.timeIndex.endDate) {
      endDate = this.store.timeIndex.endDate;
    }
    return endDate;
  }

  getDefaultGranularity(){
    return this.dashboardConfig.getGlobalRangeSelectorConfig().defaultGranularity;
  }

  async loadStore({type = "main", refetch =false}: {type?: "main" | "comparison", refetch?:boolean}): Promise<LineItemsStore> {

    let query = this.dashboardConfig.getQuery();

    if(this.constraints?.storeIds && this.constraints?.storeIds?.length>0){
      console.info("Applying storeIds here as", this.constraints.storeIds);
      query = query.withStoreIds(this.constraints?.storeIds);
    }

    if(type === "comparison") {
      query = query.withStoreIds(this.storeIdsForScenarioComparison);
    }

    console.time("PROC: Loading store")
    this.isLoading = true;

    this.handler.fireEvent(EVENTS.STORE_LOADING, {type});

    let storeToManage = type === "comparison" ? this.storeToCompareWith : this.store;
    //Todo: This is probably legacy, we can remove it
    if(!refetch && storeToManage){
      this.initializeStore(storeToManage);
      this.isLoading = false;
      this.markStoreAsReady();
      return storeToManage;
    }

    let persistence = buildLineItemsStorePersistence();
    const company = authStorage.getCompany();
    let { collection } = getAmProjectConfig(company);

    let receivedNewStore = await persistence.query(collection, query);
    let newStore = receivedNewStore.clone();
    this.initializeStore(newStore);
    if(type === "comparison") {
      this.storeToCompareWith = newStore;
    } else {
      this.store = newStore;
    }

    this.globalContext = {
      ...this.globalContext,
      utcDateRange: this.getDateRangeUsingDefaultTimeSlotSize(this.store.timeIndex.startDate),
    }


    this.handler.fireEvent(EVENTS.STORE_LOADED, newStore);
    this.markStoreAsReady();

    this.processPreRenders();

    this.isLoading = false;

    console.timeEnd("PROF: Loading store")
    return this.store;
  }

  processPreRenders() {

    this.globalProps = {}
    values(this.dashboardConfig.getWidgets())
      .forEach((widget) => {
        widget.preRender(this.buildDashboardContext({}))
      });
  }

  markStoreAsReady(){
    this.handler.fireEventDebounced(EVENTS.STORE_READY);
  }

  clearCache() {
    this.store.clearExecutionCache();
    this.storeToCompareWith?.clearExecutionCache();
  }
  getStore(): LineItemsStore {
    //TODO: THis should be removed from here as this is very inefficient

    if(this.globalContext?.filterDetails?.filterQuery) {
      return this.store.view(this.globalContext.filterDetails.filterQuery);
    }
    return this.store
  }

  getLastStoreToCompareWith() {

    if(!this.storeToCompareWith){
      return null;
    }

    if(this.storeToCompareWith){
      //TODO: This should be removed from here as this is very inefficient
      this.initializeStore(this.storeToCompareWith);
    }
    if(this.globalContext?.filterDetails?.filterQuery) {
      return this.storeToCompareWith!.view(this.globalContext.filterDetails.filterQuery);
    }
    return this.storeToCompareWith!
  }

  getTemplateStore(){
    return this.templateStore;
  }


  private initializeStore(targetStore: LineItemsStore){
    const company = authStorage.getCompany();
    let { namespace } = getAmProjectConfig(company);


    if(namespace === 'PUBLICDEMO'){ // @TODO in next iteration move this to the aggregation builder
      targetStore.getDataSet().addTimedGroupingLineItemsByField('cname', {
        inheritedFields: ['subSection'],
        groupingLabel: 'store_sourceLabel',
        groupOperationMap: {
          "Performance Ratio": "avg",
          "Generation Variance": "avg",
          "Revenue Variance": "avg"
        }
      });
    } else if(namespace === 'Ampyr'){
      targetStore.getDataSet().addTimedGroupingLineItemsByField('cname', {
        inheritedFields: DEFAULT_INHERITED_FIELDS_ON_GROUP_AGGREGATE,
        // @TODO: This needs to be configurable via the DashboardBuilder
        groupOperationMap: {
          "MOIC": "avg",
          "Cash-on-Cash Yield": "avg",
          "Commerical Operation Date": "first",
          "Commercial Operation Date": "first"
        }
      });
    } else {
      targetStore.getDataSet().addTimedGroupingLineItemsByField('cname', {
        inheritedFields: DEFAULT_INHERITED_FIELDS_ON_GROUP_AGGREGATE,
        groupingLabel: 'store_sourceLabel',
      });
    }

    // targetStore.getDataSet().addParam("Corporate Tax", 0.1);
    targetStore.getDataSet().addLineItems(this.dashboardConfig.getExtraLineItems({excludeTypes: ['Template']}));
    this.templateStore?.getDataSet().addLineItems(this.dashboardConfig.getExtraLineItems({getByTypes: ['Template']}))
    mergeTemplateStoreIntoStore(targetStore, this.templateStore,true);

    this.applyLineItemExtensions(targetStore);

    this.companyModule.onStoreInit(targetStore, this.amDashboardDto.id)

  }

  applyLineItemExtensions(store: LineItemsStore) {

    this.dashboardConfig.getLineItemExtensions().forEach((extension) => {
      let lineItems = values(
        store.getLineItems(StoreQuery.byNames(extension.lineItemNames, true))
      );

      lineItems = lineItems.filter((li) => {
        if(extension.excludeGroupingLineItems) {
          return !li.fields.hasField(GROUPING_LINE_ITEM_KEY)
        }
        return true;
      });

      store.getDataSet().extend(
        extension.code,
        values(lineItems) as TimedLineItem[]
      )
    })
  }

  renderConfig() {
    if(!this.hasSelectedWidget()) {
      return null
    }
    let store = this.store;
    let query = this.dashboardConfig.getQuery();

    return this.getSelectedWidget().renderConfig({
      store,
      query
    },
      (config: any) => {
          this.updateWidgetConfig(config);
      })
  }

  buildDashboardContext(extra: BuilderContext): BuilderContext {
    return {
      ...extra,
      appContext: this,
      query: this.dashboardConfig.getQuery(),
      dashboardConfig: this.dashboardConfig,
      globalContext: this.globalContext,
      readOnly: this.readOnly
    }
  }

  renderWidget({widgetId, context}:{widgetId: string, context: BuilderContext}) {
    let widget = this.dashboardConfig.getByID(widgetId);
    return widget.render(this.buildDashboardContext(context))
  }

  async save(companyId: string) {
    if(this.amDashboardDto.exposedAsInjectionTemplate === false){
      delete this.amDashboardDto.injectionTemplateConfig;
    }

    return updateAmDashboard(companyId,
      {
        ...this.amDashboardDto,
        data: this.dashboardConfig.serialize(),
      }, this.reportTemplateExcelFile)
  }

  updateWidgetConfig(localConfig: any) {
    if(localConfig.machineName) {
      this.getConfig().getByID(this.selectedWidget)!.machineName = localConfig.machineName;
    }
    this.dashboardConfig.setWidgetConfig(this.selectedWidget, localConfig);
  }

  getWidgetRegistry() {
      return getWidgetTypes();
  }
}

export function getSortIndex(config: any) {
  return config?.containerData?.widgetIndex || 0;
}
